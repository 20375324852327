
import Alpine from 'alpinejs';
// eslint-disable-next-line no-unused-vars
const DEFAULT_ICON = "public/techs/icons/default-icon.png";
Alpine.data('skills', () => ({
    search: '',
    get filteredFrontSkills() {
        console.log(this.search);
        return this.frontEnd.filter(
            skill => {
                return skill.title.toUpperCase().includes(this.search.toUpperCase())
            }
        )
    },
    get filteredBackSkills() {
        return this.backEnd.filter(
            skill => {
                return skill.title.toUpperCase().includes(this.search.toUpperCase())
            }
        )
    },
    frontEnd: [
        {title: "Javascript",image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg"},
        {title: "React", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg"},
        {title: "React Material", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/materialui/materialui-original.svg"},
        {title: "Angular", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/angularjs/angularjs-original.svg"},
        {title: "Angular Material", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/materialui/materialui-plain.svg"},
        {title: "Typescript", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg"},
        {title: "Jquery", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jquery/jquery-plain-wordmark.svg"},
        {title: "Jquery UI", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jquery/jquery-original.svg"},
        {title: "RxJS", image: "public/techs/icons/rxjs.png"},
        {title: "Redux", image: "public/techs/icons/redux.svg"},
        {title: "Jest", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jest/jest-plain.svg"},
        {title: "Jasmine", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jasmine/jasmine-plain.svg"},
        {title: "Scss", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg"},
        {title: "Css", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg"},
        {title: "HTML5", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg"},
        {title: "Ionic", image: "public/techs/icons/ionic.svg"},
        {title: "Bootstrap", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg"},
        {title: "Webpack", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/webpack/webpack-original.svg"},
        {title: "Gulp", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/gulp/gulp-plain.svg"},
        {title: "Axios", image: "public/techs/icons/axios.png"},
        {title: "esLint", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/eslint/eslint-original.svg"},
        {title: "JSF", image: "public/techs/icons/jsf.png"},
        {title: "Primefaces", image: "public/techs/icons/primefaces.png"},
        {title: "Alpinejs", image: "public/techs/icons/alpine.svg"},
        {title: "React testing library", image: "public/techs/icons/testing-library.png"},
        {title: "npm", image: "public/techs/icons/npm.png"},
        {title: "Lodash", image: "public/techs/icons/lodash.svg"},
    ],
    backEnd: [
        { title: "Java", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg"},
        { title: "Spring", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/spring/spring-plain-wordmark.svg"},
        { title: "Spring Boot", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/spring/spring-plain.svg"},
        { title: "Java EE", image: "public/techs/icons/java-ee.png"},
        { title: "Spring Cloud", image: "public/techs/icons/springcloud.jpg"},
        { title: "Spring WebFlux", image: "public/techs/icons/webflux.png"},
        { title: "Spring security", image: "public/techs/icons/spring-security.jpg"},
        { title: "Spring data", image: "public/techs/icons/spring-data.png"},
        { title: "Spring data JPA", image: "public/techs/icons/spring-data-jpa.png"},
        { title: "Spring data MongoDB", image: "public/techs/icons/spring-data.mongodb.png"},
        { title: "Spring cloud contract", image: "public/techs/icons/spring-cloud-contract.png"},
        { title: "Spring cloud feign", image: "public/techs/icons/feing.png"},
        { title: "Maven", image: "public/techs/icons/maven.png"},
        { title: "Jasper Report", image: "public/techs/icons/jasper-report2.png"},
        { title: "SOAP", image: "public/techs/icons/soap.png"},
        { title: "Grails", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/grails/grails-original.svg"},
        { title: "Groovy", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/groovy/groovy-original.svg"},
        { title: "Gradle", image: "public/techs/icons/gradle.png"},
        { title: "SQL", image: "public/techs/icons/oraclesql.png"},
        { title: "Hibernate", image: "public/techs/icons/hibernate.svg"},
        { title: "MongoDB", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original.svg"},
        { title: "Open API", image: "public/techs/icons/openapi.svg"},
        { title: "Junit5", image: "public/techs/icons/junit5.png"},
        { title: "Mockito", image: "public/techs/icons/mockito.png"},
        { title: "Hamcrest", image: "public/techs/icons/hamcrest.jpg"},
        { title: "Selenium", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/selenium/selenium-original.svg"},
        { title: "Test Container", image: "public/techs/icons/test-container.png"},
        { title: "Kafka", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/apachekafka/apachekafka-original.svg"},
        { title: "Java FX", image: "public/techs/icons/javafx.png"},
        { title: "Jenkins", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jenkins/jenkins-original.svg"},
        { title: "Git", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg"},
        { title: "GitLab", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/gitlab/gitlab-original.svg"},
        { title: "GitHub", image: "public/techs/icons/github.png"},
        { title: "Assertj", image: "public/techs/icons/asserj.png"},
        { title: "Azure", image: "public/techs/icons/azure.svg"},
        { title: "Cucumber", image: "public/techs/icons/cucumber.png"},
        { title: "Liquid Base", image: "public/techs/icons/liquidbase.png"},
        { title: "EhCache", image: "public/techs/icons/ehcache.png"},
        { title: "Redis", image: "public/techs/icons/redis.png"},
        { title: "gRPC", image: "public/techs/icons/grpc.png"},
        { title: "PHP", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/php/php-plain.svg"},
        { title: "Symfony", image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/symfony/symfony-original.svg"}
    ],
}));