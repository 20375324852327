//css
import "./index.scss";
import "highlight.js/styles/default.css"
//js
import 'bootstrap';
import "jquery";
import "./js/resume";
import "./js/modal-manager";
import "./js/portfolio";
import "./js/skills";
import "./js/code";
import "./js/img-zoom";


//vendors
import "font-awesome/css/font-awesome.min.css";
import "devicon";
import "simple-line-icons/css/simple-line-icons.css";
import "jquery.easing";

//alpine init
import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start();

//hightlight library
import hljs from 'highlight.js';
import javascript from 'highlight.js/lib/languages/java';
hljs.registerLanguage('javascript', javascript);
hljs.highlightAll();