import Alpine from 'alpinejs';
import hljs from 'highlight.js';
Alpine.data('code', () => ({
    codeExamples: {
        independientJdkPom: hljs.highlight(`
<plugins>
    <plugin>
    <!-- generation of zip program: this will generate a zip with the whole app in target -->
    <execution>
        <id>zipApplication</id>
        <phase>package</phase>
        <goals>
            <goal>single</goal>
        </goals>
        <configuration>
            <appendAssemblyId>false</appendAssemblyId>
            <descriptors>
                <!-- configuration of how generate the zip -->
                <descriptor>src/main/assembly/zip.xml</descriptor>
            </descriptors>
        </configuration>
    </execution>
    </plugin>
</plugins>
        `,{ language: 'xml' }).value,
        independientJdkZip: hljs.highlight(`
<plugins>
    <plugin>
    <!-- generation of zip program: this will generate a zip with the whole app in target -->
    <execution>
        <id>zipApplication</id>
        <phase>package</phase>
        <goals>
            <goal>single</goal>
        </goals>
        <configuration>
            <appendAssemblyId>false</appendAssemblyId>
            <descriptors>
                <!-- configuration of how generate the zip -->
                <descriptor>src/main/assembly/zip.xml</descriptor>
            </descriptors>
        </configuration>
    </execution>
    </plugin>
</plugins>
        `,{ language: 'xml' }).value,
        openApiValidationDefinition: hljs.highlight(`
/flight-info:
get:
    tags:
    - flight-controller
    summary: getFlightInfo
    operationId: getFlightInfo
    parameters:
    - name: flightNumber
        in: query
        description: flight number
        required: true
        schema:
        type: integer
        example: 7539
    - name: flightDate
        in: query
        description: flight date
        required: true
        schema:
        type: string
        format: date-time
        example: "2019-02-19T05:40:20-01:00"
    responses:
    '200':
        content:
        application/json:
            schema:
            "$ref": "#/components/schemas/FlightInfoDto"
    '401':
        description: Bad Parameter
        content:
        application/json:
            schema:
            "$ref": "../smart-aviation-api.yml#/components/schemas/GenericErrorResponseDto"
    '404':
        description: Not Found
        content:
        application/json:
            schema:
            "$ref": "../smart-aviation-api.yml#/components/schemas/GenericErrorResponseDto"
            `,{ language: 'xml' }).value
        ,
        cachingConfiguration: hljs.highlight(`
<config xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
    xmlns="http://www.ehcache.org/v3"
    xmlns:jsr107="http://www.ehcache.org/v3/jsr107"
    xsi:schemaLocation="
        http://www.ehcache.org/v3 http://www.ehcache.org/schema/ehcache-core-3.0.xsd
        http://www.ehcache.org/v3/jsr107 http://www.ehcache.org/schema/ehcache-107-ext-3.0.xsd">

    <cache alias="productPriceCache">
        <key-type>com.ricardocreates.dto.FilterProductPrice</key-type>
        <value-type>com.ricardocreates.model.ProductPrice</value-type>
        <expiry>
            <!-- we should increase this seconds in a real app -->
            <ttl unit="seconds">30</ttl>
        </expiry>
        <resources>
            <heap unit="entries">2</heap>
            <offheap unit="MB">10</offheap>
        </resources>
    </cache>
</config>`,{ language: 'xml' }).value,
        loggingMavenLibrary: hljs.highlight(`
<!-- we use slf4j api, so in our library we will work with the interface
 and in the app will work with the implementation of the interface -->
<dependency>
    <groupId>org.slf4j</groupId>
    <artifactId>slf4j-api</artifactId>
    <version>\${slf4j-api.version}</version>
</dependency>
<!-- we use slf4j implementation in our library but only for tests not in the final jar -->
<dependency>
    <groupId>org.slf4j</groupId>
    <artifactId>slf4j-simple</artifactId>
    <version>\${slf4j-api.version}</version>
    <scope>test</scope>
</dependency>
        `,{ language: 'xml' }).value,
        loggingMavenApp: hljs.highlight(`
<!--log4j2 -->
<dependency>
    <groupId>org.apache.logging.log4j</groupId>
    <artifactId>log4j-api</artifactId>
    <version>\${log4j2.version}</version>
</dependency>
<!-- binding log4j2 with slf4j -->
<dependency>
    <groupId>org.apache.logging.log4j</groupId>
    <artifactId>log4j-slf4j-impl</artifactId>
    <version>\${log4j2.version}</version>
</dependency>
        `,{ language: 'xml' }).value,
        scssLoopExample: hljs.highlight(`
//margins
$marginSizes: ("tiny": 0.25em, "small": 0.5em, "large": 1em, "extra-large": 1.5em);
@each $name, $size in $marginSizes {
    .margin-top-bot-#{$name} {
    margin-top: $size;
    margin-bottom: $size;
    }
}
        `,{ language: 'scss' }).value,
        scssLoopResult: hljs.highlight(`
.margin-top-bot-tiny {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}
.margin-top-bot-small {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
.margin-top-bot-large {
    margin-top: 1em;
    margin-bottom: 1em;
}
.margin-top-bot-extra-large {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}
        `,{ language: 'css' }).value,
    },
    codeMenu: [
        {
            id: "group1",
            items: [
                {
                    title: "title",
                    codeBody: `
                        <strong>code explanaiton<strong>
                    `
                },
                {
                    title: "title",
                    codeBody: `
                        <strong>code explanaiton<strong>
                    `
                }
            ]
        },
        {
            id: "group2",
            items: [
                {
                    title: "title",
                    codeBody: `
                        <strong>code explanaiton<strong>
                    `
                },
                {
                    title: "title",
                    codeBody: `
                        <strong>code explanaiton<strong>
                    `
                }
            ]
        }
    ]
}));