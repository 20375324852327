
import Alpine from 'alpinejs';

Alpine.data('portfolio', () => ({
    projects: [
        {
            id: "parkManager",
            miniature: "public/projects/park-manager/parkManager-miniature.png",
            title: "Park Manager",
            description: `Car Manager Application`,
            subText: "Created at June of 2023"
        },
        {
            id: "gallaCiencia",
            miniature: "public/projects/galla-ciencia/galla-ciencia-miniatura.png",
            title: "Galla Ciencia",
            description: `Tailor-made web store online literary magazines, offer management,
                order management, card payments or bank transfer,
                order management with login security access.`,
            subText: "Created at June of 2015"
        },
        {
            id: "zanori",
            miniature: "public/projects/zanori/zanori-miniatura.png",
            title: "Zanori",
            description: `Tailor-made web recipes online with the possibility of editing,
                 creating, eliminating recipes with user comments.`,
            subText: "Created at July of 2016"
        },
        {
            id: "sigue",
            miniature: "public/projects/sigue-project/sigue-expedient.jpg",
            title: "Call center \"Sigue\"",
            description: `call center against abuse. Grails angular web.`,
            subText: "Created at March of 2019"
        },
        {
            id: "tempo",
            miniature: "public/projects/tempo-project/tempo-manual.png",
            title: "Researcher teacher manager",
            description: `
                Researcher teacher manager.
                Manages Reseacher's project schedules
            `,
            subText: "Created at March of 2018"
        },
        {
            id: "plantMonitor",
            miniature: "public/projects/planta-arduino/planta-arduino-miniatura.png",
            title: "Plant monitor",
            description: `
                Ionic Plant monitor using arduino and nodejs.
                final project course.
            `,
            subText: "Created at March of 2015"
        },
        {
            id: "translator",
            miniature: "public/projects/translator/translator-cambridge.png",
            title: "Translator",
            description: `Java FX and Java17 modular translator app`,
            subText: "Created at Januray of 2022"
        },
        {
            id: "resizePlugin",
            miniature: "public/projects/resize-plugin/resize-final-result.png",
            title: "Webpack image resize plugin",
            description: `
                    WebPack image resize plugin. Allow get from 1 image several smaller versions to optimize web traffic.
            `,
            subText: "Created at March of 2020"
        },
        {
            id: "taskManager",
            miniature: "public/projects/sgpm/login-miniatura.jpg",
            title: "Task Manager",
            description: `Business application in charge of managing worker tasks, by customer,
                 building and plant, allows the option to assign tasks, rate them and see the status in which they are and their corresponding
                 managers and executors of these.`,
            subText: "Created at September of 2016"
        },
        {
            id: "minesweeper",
            miniature: "public/projects/buscaminas/buscaminas-miniatura.png",
            title: "Minesweeper",
            description: `Android minesweeper app`,
            subText: "Created at March of 2014"
        },
    ]
}));