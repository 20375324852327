import * as bootstrap from 'bootstrap';
//window.bootstrap = bootstrap;
(function($) {
  "use strict"; // Start of use strict

  // Smooth scrolling using jQuery easing
  $('a.js-scroll-trigger[href*="#"]:not([href="#"])').on("click",function() {
    let pathnameVar = Object.assign({}, location.pathname);
    let thisPathName = Object.assign({}, this.pathname);
    if (pathnameVar.replace(/^\//, '') == thisPathName.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: (target.offset().top)
        }, 1000, "easeInOutExpo");
        return false;
      }
    }
  });

  // Closes responsive menu when a scroll trigger link is clicked
  /*$('.js-scroll-trigger').on("click",function() {
    $('.navbar-collapse').collapse('hide');
  });*/
  
  $('.js-scroll-trigger').on("click",function() {
    //new bootstrap.Collapse($('.navbar-collapse'));
  });

  // Activate scrollspy to add active class to navbar items on scroll
  /*$('body').scrollspy({
    target: '#sideNav'
  });*/
  new bootstrap.ScrollSpy(document.body, {
    target: '#sideNav'
  });

})(jQuery); // End of use strict
