const IMG_DIR = "public/projects"
const parkManager = {
    title: "Park Manager",
    images: [
        `${IMG_DIR}/park-manager/create-coupon.png`,
        `${IMG_DIR}/park-manager/coupon-chooose-location.png`,
        `${IMG_DIR}/park-manager/filter-server-paginated.png`,
        `${IMG_DIR}/park-manager/purchase-behalf-1.png`,
        `${IMG_DIR}/park-manager/purchase-behalf-2.png`,
        `${IMG_DIR}/park-manager/purchase-behalf-3.png`,
    ],
    summary: `
        <p>
            Tailor-made web store online literary magazines,<br/>
            offer management, order management, card payments or bank transfer, order management with login security access.
        </p>
    `,
    tech: `
        <h5>Front end</h5>
        <div>
        <table class="table">
            <thead>
                <tr>
                    <th class="w-35" scope="col">Tech</th>
                    <th class="w-60" scope="col">Detail</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Html5</td>
                    <td></td>
                </tr>
                <tr>
                    <td>React</td>
                    <td></td>
                </tr>
                <tr>
                    <td>React Testing Library</td>
                    <td></td>
                </tr>
            </tbody>
            </table>
        </div>
        <h5>Back end</h5>
        <div>
        <table class="table">
            <thead>
                <tr>
                    <th class="w-35" scope="col">Tech</th>
                    <th class="w-60" scope="col">Detail</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Java</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Spring boot</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Spring web flux</td>
                    <td></td>
                </tr>
                <tr>
                    <td>MongoDB</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Wiremock</td>
                    <td></td>
                </tr>
            </tbody>
            </table>
        </div>
    `
};
const gallaCiencia = {
    title: "Galla Ciencia",
    images: [
        `${IMG_DIR}/galla-ciencia/galla-ciencia.png`,
        `${IMG_DIR}/galla-ciencia/gallaciencia-imagen1.png`,
        `${IMG_DIR}/galla-ciencia/gallaciencia-imagen2.jpg`
    ],
    summary: `
        <p>
            Tailor-made web store online literary magazines,<br/>
            offer management, order management, card payments or bank transfer, order management with login security access.
        </p>
    `,
    tech: `
        <h5>Front end</h5>
        <div>
        <table class="table">
            <thead>
                <tr>
                    <th class="w-35" scope="col">Tech</th>
                    <th class="w-60" scope="col">Detail</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Html5</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Angular</td>
                    <td></td>
                </tr>
            </tbody>
            </table>
        </div>
        <h5>Back end</h5>
        <div>
        <table class="table">
            <thead>
                <tr>
                    <th class="w-35" scope="col">Tech</th>
                    <th class="w-60" scope="col">Detail</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Java</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Spring boot</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Mysql</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Hibernate</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Stripe</td>
                    <td>Payment GateWay</td>
                </tr>
            </tbody>
            </table>
        </div>
    `
};
const zanori = {
    title: "Zanori Recipe Web",
    images: [
        `${IMG_DIR}/zanori/zanori-imagen1.jpg`,
        `${IMG_DIR}/zanori/zanori.png`,
    ],
    summary: `
        <p>
            Tailor-made web recipes online with the possibility of editing, creating, eliminating recipes with user comments.
        </p>
    `,
    tech: `
        <h5>Front end</h5>
        <div>
        <table class="table">
            <thead>
                <tr>
                    <th class="w-35" scope="col">Tech</th>
                    <th class="w-60" scope="col">Detail</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Html5</td>
                    <td></td>
                </tr>
                <tr>
                    <td>React</td>
                    <td></td>
                </tr>
            </tbody>
            </table>
        </div>
        <h5>Back end</h5>
        <div>
        <table class="table">
            <thead>
                <tr>
                    <th class="w-35" scope="col">Tech</th>
                    <th class="w-60" scope="col">Detail</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Java</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Spring boot</td>
                    <td></td>
                </tr>
                <tr>
                    <td>MongoDB</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Spring data mongodb</td>
                    <td></td>
                </tr>
            </tbody>
            </table>
        </div>
    `
};
const tempo = {
    title: "Researcher teacher manager",
    images: [
        `${IMG_DIR}/tempo-project/tempo-schedule.png`,
        `${IMG_DIR}/tempo-project/tempo-administrators.png`,
        `${IMG_DIR}/tempo-project/tempo-administrators-add.png`,
        `${IMG_DIR}/tempo-project/tempo-administrator-add-2.png`,
        `${IMG_DIR}/tempo-project/tempo-schedule.png`,
        `${IMG_DIR}/tempo-project/tempo-manual.png`,
        `${IMG_DIR}/tempo-project/tempo-table-detail.png`,
        `${IMG_DIR}/tempo-project/tempo-timeline.png`,
    ],
    summary: `
        <p>
            Researcher teacher manager.
            Manages Reseacher's project schedules
        </p>
    `,
    tech: `
        <h5>Front end</h5>
        <div>
        <table class="table">
            <thead>
                <tr>
                    <th class="w-35" scope="col">Tech</th>
                    <th class="w-60" scope="col">Detail</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>JSF</td>
                    <td></td>
                </tr>
                <tr>
                    <td>PRIMEFACES</td>
                    <td></td>
                </tr>
            </tbody>
            </table>
        </div>
        <h5>Back end</h5>
        <div>
        <table class="table">
            <thead>
                <tr>
                    <th class="w-35" scope="col">Tech</th>
                    <th class="w-60" scope="col">Detail</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Java</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Spring boot</td>
                    <td></td>
                </tr>
                <tr>
                    <td>OracleSQL</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Hibernate</td>
                    <td></td>
                </tr>
            </tbody>
            </table>
        </div>
    `
};
const taskManager = {
    title: "Task Manager",
    images: [
        `${IMG_DIR}/sgpm/login.jpg`,
        `${IMG_DIR}/sgpm/sgpm-tareas1.jpg`,
        `${IMG_DIR}/sgpm/esquemaBBDD.jpg`,
    ],
    summary: `
        <p>
            Business application in charge of managing worker tasks, by customer,
            building and plant, allows the option to assign tasks, rate them and see the status in which they are and their corresponding
            managers and executors of these.
        </p>
    `,
    tech: `
        <h5>Front end</h5>
        <div>
        <table class="table">
            <thead>
                <tr>
                    <th class="w-35" scope="col">Tech</th>
                    <th class="w-60" scope="col">Detail</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Html5</td>
                    <td></td>
                </tr>
                <tr>
                    <td>React</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Webpack</td>
                    <td></td>
                </tr>
            </tbody>
            </table>
        </div>
        <h5>Back end</h5>
        <div>
        <table class="table">
            <thead>
                <tr>
                    <th class="w-35" scope="col">Tech</th>
                    <th class="w-60" scope="col">Detail</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Java</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Spring boot</td>
                    <td></td>
                </tr>
                <tr>
                    <td>postgresql</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Spring data</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Spring security</td>
                    <td></td>
                </tr>
            </tbody>
            </table>
        </div>
    `
}
const plantMonitor = {
    title: "Plant monitor Ionic App",
    images: [
        `${IMG_DIR}/planta-arduino/planta-arduino.png`,
        `${IMG_DIR}/planta-arduino/planta-arduino1-movil.png`,
        `${IMG_DIR}/planta-arduino/planta-arduino2-movil.png`,
        `${IMG_DIR}/planta-arduino/planta-arduino3-movil.png`,
        `${IMG_DIR}/planta-arduino/planta-arduino3-pc.png`,
        `${IMG_DIR}/planta-arduino/planta-arduino4-movil.png`,
    ],
    summary: `
        <p>
            Ionic Plant monitor using arduino and nodejs.
            final project course.
        </p>
    `,
    tech: `
        <h5>Front end</h5>
        <div>
        <table class="table">
            <thead>
                <tr>
                    <th class="w-35" scope="col">Tech</th>
                    <th class="w-60" scope="col">Detail</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>HTML5</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Ionic</td>
                    <td></td>
                </tr>
            </tbody>
            </table>
        </div>
        <h5>Back end</h5>
        <div>
        <table class="table">
            <thead>
                <tr>
                    <th class="w-35" scope="col">Tech</th>
                    <th class="w-60" scope="col">Detail</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Nodejs</td>
                    <td></td>
                </tr>
                <tr>
                    <td>MongoDB</td>
                    <td></td>
                </tr>
            </tbody>
            </table>
        </div>
    `
};
const translator = {
    title: "Translator",
    images: [
        `${IMG_DIR}/translator/transalator-select-language.png`,
        `${IMG_DIR}/translator/translator-cambridge.png`,
        `${IMG_DIR}/translator/translator-google.png`,
        `${IMG_DIR}/translator/translator-libreTranslate.png`,
        `${IMG_DIR}/translator/translator-libreTranslate-2.png`,
    ],
    summary: `
        <p>
            Java FX and Java17 modular translator app
        </p>
    `,
    tech: `
        <h5>Technologies</h5>
        <div>
        <table class="table">
            <thead>
                <tr>
                    <th class="w-35" scope="col">Tech</th>
                    <th class="w-60" scope="col">Detail</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Java FX</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Java 17</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Guava</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Junit</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Test FX</td>
                    <td></td>
                </tr>
            </tbody>
            </table>
        </div>
    `
}
const sigue = {
    title: "Call Center \"Sigue\"",
    images: [
        `${IMG_DIR}/sigue-project/sigue-calendar.jpg`,
        `${IMG_DIR}/sigue-project/sigue-detail-agression.jpg`,
        `${IMG_DIR}/sigue-project/sigue-expedient.jpg`,
        `${IMG_DIR}/sigue-project/sigue-expedient-detail-1.jpg`,
        `${IMG_DIR}/sigue-project/sigue-ocupations.jpg`,
    ],
    summary: `
        <p>
            call center against abuse. Grails angular web.
        </p>
    `,
    tech: `
        <h5>Technologies</h5>
        <div>
        <table class="table">
            <thead>
                <tr>
                    <th class="w-35" scope="col">Tech</th>
                    <th class="w-60" scope="col">Detail</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Grails</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Groovy</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Angular</td>
                    <td></td>
                </tr>
            </tbody>
            </table>
        </div>
    `
};
const resizePlugin = {
    title: "Webpack Resize Plugin",
    images: [
        `${IMG_DIR}/resize-plugin/resize-configure-plugin.png`,
        `${IMG_DIR}/resize-plugin/resize-packagejson.png`,
        `${IMG_DIR}/resize-plugin/resize-final-result.png`,
    ],
    summary: `
        <p>
            Java FX and Java17 modular translator app
        </p>
    `,
    tech: `
        <h5>Technologies</h5>
        <div>
        <table class="table">
            <thead>
                <tr>
                    <th class="w-35" scope="col">Tech</th>
                    <th class="w-60" scope="col">Detail</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Webpack</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Javascript</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Jest</td>
                    <td></td>
                </tr>
                <tr>
                    <td>NPM</td>
                    <td></td>
                </tr>
            </tbody>
            </table>
        </div>
    `
}
const minesweeper = {
    title: "Minesweeper",
    images: [
        `${IMG_DIR}/buscaminas/buscaminas2.png`,
        `${IMG_DIR}/buscaminas/buscaminas3.png`,
        `${IMG_DIR}/buscaminas/buscaminas4.png`,
        `${IMG_DIR}/buscaminas/buscaminas1.png`,
    ],
    summary: `
        <p>
            Android minesweeper app
        </p>
    `,
    tech: `
        <h5>Technologies</h5>
        <div>
        <table class="table">
            <thead>
                <tr>
                    <th class="w-35" scope="col">Tech</th>
                    <th class="w-60" scope="col">Detail</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Android</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Java</td>
                    <td></td>
                </tr>
            </tbody>
            </table>
        </div>
    `
}
const modals = {
    parkManager,
    gallaCiencia,
    zanori,
    taskManager,
    plantMonitor,
    translator,
    sigue,
    tempo,
    resizePlugin,
    minesweeper
}
$(() => {
    //modal manager
    $(".project-open-modal").on("click",function() {
        const modal = modals[this.id];
        console.log({modal});
        console.log("id:"+this.id);
        $("#modalTitle").html(modal.title);
        $("#modalSummary").html(modal.summary);
        $("#modalTech").html(modal.tech);

        //carusel images
        let imagesToAdd = "";
        modal.images.forEach((image,index) => {
            let imageToAdd = `
                <div class="carousel-item ${index == 0 ? "active" : ""}">
                    <img src="${image}" class="d-block w-100" alt="image-project">
                </div>
            `;
            imagesToAdd += imageToAdd;
            
        });
        $("#carouselImages").html(imagesToAdd);
        //carusel indicators
        let indicatorsToAdd = "";
        modal.images.forEach((image,index) => {
            let indicatorToAdd = `
                <button type="button" data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="${index}" 
                class="carousel-indicator-custom ${index == 0 ? "active" : ""}"
                aria-current="true" aria-label="Slide ${index+1}"></button>
            `;
            indicatorsToAdd += indicatorToAdd;
            
        });
        $("#carouselIndicators").html(indicatorsToAdd);
    });
});